<template>
    <div id="index" ref="appRef">
        <div class="bg Anim">
            <div class="host-body">
                <!-- 标题行 -->
                <div class="title">
                    <img src="../assets/img/logo.png" alt="" class="big-title-img">
                    <div class="time">
                        <span class="year">{{ dateYear }}</span>
                        <span class="week">{{ dateWeek }}</span>
                        <span class="day">{{ dateDay }}</span>
                    </div>
                </div>
                <!-- 内容 -->
                <div class="content">
                    <!-- <div class="right-top">
                        <div class="top-right">
                            <div class="item-box" v-for="(item, index) in topData" :key="index">
                                <div class="item">
                                    <img :src="item.url" alt="">
                                    <div class="item-content">
                                        <div class="item-text">{{ item.text }}</div>
                                        <div class="item-num">
                                            <span class="num">{{ item.num }}</span>
                                            <span checked="unit">{{ item.unit }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <ngDevice /> -->
                    <!-- 左边 -->
                    <div class="content-bottom">
                        <div class="left">
                            <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/beidaoguo-qr-icon.png" alt=""
                                class="QRCodeicon">
                            <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/beidaoguo-logo.png" alt=""
                                class="icon1">
                            <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/pengpeng-phone.png" alt=""
                                class="icon2">
                            <router-link to="/index">
                                <button class="button1">
                                    <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-left-icon2.png" alt=""
                                        class="icon3">
                                    <span class="button1-text">返回首页</span>
                                </button>
                            </router-link>
                            <router-link to="/page3">
                                <button class="button2">
                                    <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon3.png" alt=""
                                        class="button-img">
                                    <div class="button-text">数字农管</div>
                                </button>
                            </router-link>
                            <router-link to="/page2">
                                <button class="button2">
                                    <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon1.png" alt=""
                                        class="button-img">
                                    <div class="button-text">数字农服</div>
                                </button>
                            </router-link>
                            <router-link to="/page4">
                                <button class="button2">
                                    <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon2.png" alt=""
                                        class="button-img">
                                    <div class="button-text active">数字营销</div>
                                </button>
                            </router-link>
                        </div>
                        <!-- 右边 -->
                        <div class="right">
                            <div class="right-center">
                                <div class="cameraBox">
                                        <img class="cameraIcon"
                                            src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon12.png"
                                            alt="">
                                        <div class="line1"></div>
                                        <div class="line2"></div>
                                        <div class="camera">
                                            <div class="top-text">照八寺智慧果园JX-001</div>
                                            <!-- <video src="https://nonglian.oss-cn-shanghai.aliyuncs.com/camera1.mp4"
                                                class="camera-video" autoplay muted loop></video> -->
                                            <div class="camera-video" style="width: 250px;height: 166.17px;" id="myPlayer" ref="myPlayer"></div>
                                        </div>
                                    </div> 
                                <!-- <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon1.png" alt="" class="right-center-left-icon1">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon2.png" alt="" class="right-center-left-icon2">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon3.png" alt="" class="right-center-left-icon3">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon4.png" alt="" class="right-center-left-icon4"> -->
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page3-center-icon1.png" alt="" class="center-icon1">
                                    <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page3-center-icon2.png" alt="" class="center-icon2">
                                    <div class="deviceList-box">
                                        <img class="list" :src="item.url" alt="" v-for="(item, i) in deviceList" :key="i">
                                    </div>
                                <div class="right-center-right">
                                    <centerLeft1 />
                                    <centerLeft2 />
                                    <centerLeft3 />
                                </div>
                            </div>
                            <div class="right-bottom">
                                <centerLeft4 />
                                <centerLeft5 />
                                <centerLeft6 />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="bottom-left">
                        <dv-decoration-2 class="decoration-two1" />
                        <img src="../assets/img/bottom-icon2.png" alt="" class="bottom-img">
                    </div>
                    <div class="bottom-center"></div>
                    <div class="bottom-right">
                        <img src="../assets/img/bottom-icon1.png" alt="" class="bottom-img">
                        <dv-decoration-2 class="decoration-two2" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './page4/centerLeft1'
import centerLeft2 from './page4/centerLeft2'
import centerLeft3 from './page4/centerLeft3'
import centerLeft4 from './page4/centerLeft4'
import centerLeft5 from './page4/centerLeft5'
import centerLeft6 from './page4/centerLeft6'
import flvjs from 'flv.js'
import axios from 'axios'
// import ngDevice from '../components/ngDevice/ngDevice.vue'


export default {
    mixins: [drawMixin],
    data() {
        return {
            inAnimation: false,
            timing: null,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            outdoorTemperatureData: [
                {
                    text: '风向',
                    content: '西北'
                },
                {
                    text: '风速',
                    content: '5.9m/s'
                },
                {
                    text: '温度',
                    content: '37℃'
                },
                {
                    text: '湿度',
                    content: '45%'
                },
                {
                    text: '雨量',
                    content: '60mm'
                },
                {
                    text: '气压',
                    content: '1.01kpa'
                },
            ],
            topData: [
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon1.png',
                    text: '风力',
                    num: '0',
                    unit: '级'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon2.png',
                    text: '风速',
                    num: '0',
                    unit: 'm/s'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon3.png',
                    text: '风向',
                    num: '非法方向',
                    // unit: ''
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon4.png',
                    text: '空气温度',
                    num: '28.4',
                    unit: '℃'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon5.png',
                    text: '土壤水分',
                    num: '17.6',
                    unit: '%'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon6%20.png',
                    text: '湿度%',
                    num: '59.10',
                    unit: '%'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon7.png',
                    text: '土壤EC值',
                    num: '1092',
                    unit: "us/cm"
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon8.png',
                    text: '土壤氮',
                    num: '83',
                    unit: 'mg/KG'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon9.png',
                    text: '土壤磷',
                    num: '118',
                    unit: 'mg/KG'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon10.png',
                    text: '土壤钾',
                    num: '220',
                    unit: 'mg/KG'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon11.png',
                    text: '土壤PH值',
                    num: '7.99',
                    unit: ''
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon12.png',
                    text: '光照',
                    num: '1548',
                    unit: 'Lux'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon13.png',
                    text: '二氧化碳',
                    num: '550',
                    unit: 'ppm'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-top-icon14.png',
                    text: '当前雨量',
                    num: '0.0',
                    unit: 'mm'
                }
            ],
            deviceList: [
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon2.png'
                },
                {
                    url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon3.png'
                }
            ],
            enableProgressGesture: false,
            autoplay: true,
            showPlayBtn: false,
            showCenterPlayBtn: false,
            showFullscreenBtn: false,
            controls: true,
            windowWidth: '',
            windowHeight: '',
            flvPlayer: null,
            player: null,
            timer: null
        }
    },
    components: {
        centerLeft1,
        centerLeft2,
        centerLeft3,
        centerLeft4,
        centerLeft5,
        centerLeft6,
        // ngDevice
        //   center,
        //   bottomLeft,
        //   bottomRight
    },
    // created(){
    //     // location.reload()
    // },
    mounted() {
        this.timeFn()
        this.getLivePlayer()
    },
    beforeDestroy() {
        clearInterval(this.timing)
    },
    methods: {
        changeMsg() {
            // this.inAnimation = !this.inAnimation;
            // setTimeout(() => {
            //     this.$router.push('/page2')
            // }, 500)
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy.MM.dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        }, getLivePlayer() {
            // 生成需要的video 组件
            // var player = document.getElementById('videoElement');
            var player = document.createElement('video');
            player.id = 'video';
            player.style = 'width: 250px;height: 166.17px';
            player.enableProgressGesture = this.enableProgressGesture;
            player.controls = this.controls;
            player.muted = true;
            player.showCenterPlayBtn = this.showCenterPlayBtn;
            player.showPlayBtn = this.showPlayBtn;
            player.showFullscreenBtn = this.showFullscreenBtn;
            player.x5VideoPlayerType = 'h5-page';
            player.x5VideoPlayerFullscreen = 'false';
            player.autoplay = this.autoplay; // 以上均为 video标签的属性配置
            document.getElementById('myPlayer').appendChild(player);
            console.log(player, 'sssssssssssssssssss');
            console.log(flvjs.isSupported());
            if (flvjs.isSupported()) {
                let params = {
                    deviceId: '9J00013PAZ13140',
                    channelId: 0
                }
                let flv = ''
                axios.get('https://cloud.nongliankeji.com/apis/mate-nongguan/dahua/queryDeviceFlvLive', {
                    params: params
                }).then(res => {
                    flv = res.data.data.flv
                    console.log(res, '11111122222', flvjs);
                    this.flvPlayer = flvjs.createPlayer({
                        type: 'flv',
                        isLive: true, //<====直播的话，加个这个
                        url: flv //直播流地址
                    });
                    console.log('this.flvPlayer', this.flvPlayer, this)
                    this.flvPlayer.attachMediaElement(player);
                    this.flvPlayer.load(); //加载

                    setTimeout(() => {
                        var player2 = document.createElement('video');
                        player2.play();
                    }, 2000);
                    this.player = player;
                    this.flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {
                        console.log('播放错误', errorType, errorDetail, errorInfo);
                        this.flv_destroy();

                    });
                    this.timer = window.setInterval(() => {
                        if (!this.flvPlayer) return
                        if (this.flvPlayer.buffered.length) {
                            let end = this.flvPlayer.buffered.end(0) //获取当前buffered值
                            let diff = end - this.flvPlayer.currentTime //获取buffered与currentTime的差值
                            if (diff >= 4) {
                                // console.log('处理延迟')
                                //如果差值大于等于0.5 手动跳帧 这里可根据自身需求来定
                                this.flvPlayer.currentTime = this.flvPlayer.buffered.end(0) - 9 //手动跳帧
                            }
                        }
                    }, 2000) //2000毫秒执行一次
                })
            }


        },
        flv_start() {
            //开始
            console.log(this.player);
            this.player.play();
        },
        flv_pause() {
            //暂停
            this.player.pause();
        },
        flv_destroy() {
            //停止
            this.player.pause();
            this.player.unload();
            this.player.detachMediaElement();
            this.player.destroy();
            this.player = null;
        },
        flv_seekto() {
            // 复制其他人的  我还没用这个
            this.player.currentTime = parseFloat(document.getElementsByName('seekpoint')[0].value);
        }
    }
}
</script>
  
<style lang="scss" scoped>@import '../assets/scss/page4.scss';</style>