<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">认养人区域流向</span>
    </div>
    <dv-scroll-board :config="config" class="scroll-board" style="width:100%;height:200px" />

  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        rowNum: 5,
        columnWidth: [160, 80,150],
        headerBGC: 'transparent',
        oddRowBGC: 'rgba(61, 85, 102, 0.16)',
        evenRowBGC: 'transparent',
        header: ['认养项目', '人数','地区'],
        data: [
          ['北道果·365苹', '725','广州'],
          ['北道果·365苹', '378','上海'],
          ['北道果·365苹', '275','杭州 '],
          ['北道果·365苹', '175','北京 '],
          ['北道果·365苹', '368','深圳 '],
          ['北道果·365苹', '538','湖州 '],
        ]
      }
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  // margin-bottom: 35px;
  margin-top: -30px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;
    // margin-bottom: 10px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }
  ::v-deep .scroll-board .header .header-item {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    color: rgba(51, 204, 204, 1);
    text-align: center;
  }
  ::v-deep .scroll-board .rows .row-item .ceil{
    text-align: center;
    // text-align: center;
  }
}
</style>
