<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">每月销售额统计</span>
    </div>
    <rightCenterChart/>
    <!-- <div class="quantity">
      <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-right-icon11.png" alt="" class="quantity-img">
      <div class="item-box">
        <div class="item" v-for="(item, index) in data" :key="index">
          <div class="item-text">{{ item.text }}</div>
          <div class="num-content">
            <span class="item-num" v-change="{ eUnit: '' }">{{ item.num }}</span>
            <span class="item-unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import rightCenterChart from '@/components/echart/page4/rightCenterChart'
export default {
  components:{
    rightCenterChart
  },
  data() {
    return {
      data: [
        {
          text: '今日入园数',
          num: 1902,
          unit: '人'
        },
        {
          text: '总入园数',
          num: 10608,
          unit: '人'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;
    // margin-bottom: 17px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

}
</style>
