<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">认养情况</span>
    </div>
    <div class="quantity">
      <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/4ecaa00713494331a368543946c888a9.png" alt="" class="quantity-img">
      <!-- <div class="d-pro1"></div> -->
      <div class="item-box">
        <div class="item" v-for="(item, index) in data" :key="index">
          <div class="item-text">{{ item.text }}</div>
          <div class="num-content">
            <span class="item-num" v-change="{ eUnit: '' }">{{ item.num }}</span>
            <span class="item-unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          text: '上周新增人数',
          num: 5,
          unit: '人'
        },
        {
          text: '上周新增销售额',
          num:20000,
          unit: '元'
        },
        {
          text: '总认养人数    ',
          num: 12,
          unit: '人'
        },
        {
          text: '总销售额',
          num: 50000,
          unit: '元'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  margin-bottom: 15px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .quantity {
    display: flex;
    align-items: center;
    margin-top: 5px;

    .quantity-img {
      width: 118px;
      height: 122px;
      margin-right: 31px;
    }
.item-box{
  display: flex;
      flex-wrap: wrap;
}
    .item {
      
      margin-bottom: 9px;
      margin-right: 10px;
    }

    .item-text {
      /* width: 250px; */
      background: rgba(108, 128, 151, 0.1);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.75px;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 7px;
    }

    .num-content {
      /* width: 250px; */
      height: 30px;
      background: rgba(108, 128, 151, 0.1);
      display: flex;
      align-items: center;
      .item-num {
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 1.83px;
        color: rgba(79, 240, 255, 1);
      }

      .item-unit {
        margin-left: 5px;
      }
    }
  }
}
</style>
