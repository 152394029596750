<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
    <div class="lable-item">
      <div class="item-title">
        <div class="title">认养园区</div>
        <div class="title1">认养人数</div>
        <div class="title">占比</div>
      </div>
      <div class="item" v-for="(item, index) in lableData" :key="index">
        <div class="left">
          <div class="item-color" :style="{ 'background-color': item.color }"></div>
        <div class="item-text">{{ item.text }}</div>
        </div>
        <div class="item-num" :class="item.text=='一棵苹果树'?'num3':item.text=='一棵樱桃树'?'num4':''">{{ item.num }}</div>
        <div class="item-proportion">{{ item.proportion }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        data: [
          { value: 250, name: '一米花田' },
          { value: 250, name: '一分菜园' },
          { value: 360, name: '一棵苹果树' },
          { value: 560, name: '一棵樱桃树' },
          { value: 460, name: '一棵草莓树' },
        ]
      },
      lableData: [
        {
          // , '#7aa1c9', '#bce9fd'
          color: '#00cdcb',
          text: '东村数字果园',
          num: '1',
          proportion: '8%'
        },
        {
          // , '', '#bce9fd'
          color: '#7aa1c9',
          text: '金村数字果园',
          num: '1',
          proportion: '8%'
        },
        {
          // , '#7aa1c9', '#bce9fd'
          color: '#bce9fd',
          text: '姜家台数字果园',
          num: '2',
          proportion: '17%'
        },
        {
          // , '#7aa1c9', '#bce9fd'
          color: '#dcb576',
          text: '照八寺数字果园',
          num: '5',
          proportion: '42%'
        },
        {
          // , '#7aa1c9', '#bce9fd'
          color: '#1C51A1',
          text: '湫塬村数字果园',
          num: '3',
          proportion: '25%'
        },
      ]
    }
  },
  components: {
    Chart,
  }
}
</script>
<style lang="scss" scoped>
.bigbox {
  display: flex;
  align-items: center;

  .item-title {
    width: 220px;
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    color: rgba(79, 240, 255, 1);
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 14px;
    margin-left: 5px;
  }
  .title1{
    margin-left:60px;
    margin-right:20px;
  }

  .item {
    width: 210px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .left{
      display: flex;
      align-items: center;
    }
    .item-color {
      width: 8px;
      height: 8px;
      background: rgba(51, 204, 204, 1);
      margin-right: 10px;
    }

    .item-text {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.86px;
      /* margin: 0 17px 0 10px; */
    }

    .num3 {
      margin-left: -13px;
    }
    .num4 {
      margin-left: -13px;
    }

    .item-num,
    .item-proportion {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.75px;
      margin-left: 30px;
    }
  }
  .item-proportion{
   
  }
}
</style>  