<template>
    <div>
        <Echart :options="options" id="centreRight2Chart1" height="160px" width="200px"></Echart>
    </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
    data() {
        return {
            options: {},
        };
    },
    components: {
        Echart,
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        cdata: {
            handler(newData) {

                this.options = {
                    color: ['#00cdcb', '#7aa1c9', '#bce9fd','#dcb576','#1C51A1'],
                    // tooltip: {
                    //     trigger: 'item',
                    //     formatter: '{a} <br/>{b} : {c} ({d}%)'
                    // },
                    series: [
                        {
                            // emphasis: {
                            //     label: {
                            //         show: true
                            //     }
                            // },
                            label: {
                                show: false
                            },
                            name: '认养类别占比',
                            type: 'pie',
                            radius: [0, 70],
                            center: ['50%', '50%'],
                            roseType: 'area',
                            itemStyle: {
                                borderRadius: 8
                            },
                            data: newData.data
                        }
                    ]
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>