<template>
  <div class="bigbox">
    <!-- <Chart :cdata="cdata" /> -->
    <div class="lable-item">
      <div class="item" v-for="(item, index) in lableData" :key="index">
        <div class="LL">
          <img class="first" :src="item.img" alt="">
        <div class="left">
          <div class="item-color" :style="{ 'background-color': item.color }"></div>
          <div class="item-text">{{ item.text }}</div>
        </div>
        </div>
        <div class="right">
          <div class="item-proportion">{{ item.proportion }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        data: [
          { value: 480, name: 'Search Engine' },
          { value: 220, name: 'Direct' },
          // { value: 130, name: 'Email' },
          // { value: 100, name: 'Union Ads' },
          // { value: 70, name: 'Video Ads' }
        ]
      },
      lableData: [
        {
          img:'https://nonglian.oss-cn-shanghai.aliyuncs.com/99491b15a1f144aba4140c77faa3df34.png',
          color: 'rgba(21, 154, 255, 1)',
          text: '东村数字果园',
          proportion: '48%'
        },
        {
          img:'https://nonglian.oss-cn-shanghai.aliyuncs.com/486d23c0b9ab40a1bcfa7c47ac9de732.png',
          color: 'rgba(102, 225, 223, 1)',
          text: '金村数字果园',
          proportion: '22%'
        },
        {
          img:'https://nonglian.oss-cn-shanghai.aliyuncs.com/fa5043f02c734cc78ed0c3f2150bf4a6.png',
          color: 'rgba(208, 222, 238, 1)',
          text: '姜家台数字果园',
          proportion: '13%'
        },
        {
          img:'https://nonglian.oss-cn-shanghai.aliyuncs.com/30e77ce349334fa084b8b2cea8b69cfa.png',
          color: 'rgba(21, 99, 255, 1)',
          text: '照八寺数字果园',
          proportion: '10%'
        },
        {
          img:'https://nonglian.oss-cn-shanghai.aliyuncs.com/24a31292e30b42a3923c5dce5f6834d2.png',
          color: 'rgba(52, 255, 191, 1)',
          text: '湫塬村数字果园',
          proportion: '7%'
        },
      ]
    }
  },
  components: {
    // Chart,
  }
}
</script>
<style lang="scss" scoped>
  .LL{
    display: flex;
    align-items: center;
  }
  .first{
    margin-right: 20px;
  }
  .lable-item{
    margin-top: 10px;
  }
  .left {
      display: flex;
      align-items: center;
    }
.bigbox {
  display: flex;
  align-items: center;
  

  .item {
    width: 390px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 0 21px 0 12px;
    background: rgba(108, 128, 151, 0.1);
    position: relative;
    /* border: 1px solid gray; */

    

    .item-color {
      width: 8px;
      height: 8px;
      background: rgba(51, 204, 204, 1);
    }

    .item-text {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.86px;
      white-space: nowrap;
      margin: 0 17px 0 10px;
    }

    .item-num {
      margin-right: 18px;
    }

    .item-proportion {
      margin-top: 7px;
      font-size: 16px;
      font-weight: 700;
      /* letter-spacing: 1px; */
      color: rgba(208, 222, 238, 1);
      /* position: absolute;
      top: 50%;
      right: 21px; */
      /* transform: translate(0,-50%); */
    }
  }
}
</style>  