<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">数字营销数据</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="left-item" v-for="(item, index) in data" :key="index">
          <img src="../../assets/img/triangle.png" alt="" class="left-icon">
          <div class="left-text-content">
            <div class="left-text">{{ item.text }}</div>
            <div class="num-content">
              <span class="left-num" v-change="{ eUnit: '' }">{{ item.num }}</span>
              <span class="left-unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <bottomChart/>
      </div>
    </div>
  </div>
</template>

<script>
import bottomChart from '@/components/echart/page4/bottomChart'
export default {
  components: {
    bottomChart
  },
  data() {
    return {
      data: [
        {
          text: '触达人数',
          num: 22388,
          unit: '个'
        },
        {
          text: '转化率',
          num: 11,
          unit: '%'
        },
        {
          text: '分享数量',
          num: 5842,
          unit: '条'
        },
        {
          text: '销售额',
          num: 128,
          unit: '万元'
        },
      ],
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 676px;
  margin: 0px 33px 10px 33px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title2.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    margin-bottom: 31px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    display: flex;

    .left {
      .left-item {
        width: 170px;
        height: 45px;
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid rgba(108, 128, 151, 1);
        margin-bottom: 11px;
        &:nth-last-child(1){
          margin-bottom: 11px;
        }

        .left-icon {
          position: absolute;
          top: 5px;
          left: 5px;
        }

        .left-img {
          position: absolute;
          right: 12px;
          top: 15px;
        }

        .left-text-content {
          margin-left: 26px;

          .left-text {
            font-size: 12px;
            font-weight: 400;
            color: rgba(217, 231, 255, 1);
            margin-bottom: 6px;
          }

          .left-num {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 2px;
            color: rgba(79, 240, 255, 1);
            margin-right: 4px;
          }

          .left-unit {
            font-size: 12px;
            font-weight: 400;
            color: rgba(217, 231, 255, 1);
          }

          z-index: 99;
        }
      }

      .left-item::after {
        content: "";
        background-image: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft1-bg.png'); // 背景图片的路径
        opacity: 0.3;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -99;
      }
    }
  }
}
</style>
