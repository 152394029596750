<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">认养果园</span>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in data" :key="index">
        <div class="icon-bigBox">
          <div class="icon-smallBox">
            <img :src="item.url" alt="">
          </div>
        </div>
        <div class="text-content">
          <div class="item-text">{{ item.text }}</div>
          <div class="num-content">
            <span class="item-num">{{ item.num }}</span>
            <span class="item-unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-title">
      <span class="text">认养类别占比</span>
    </div>
    <div class="title-border">
      <div class="border1"></div>
      <div class="border2"></div>
      <div class="border3"></div>
      <div class="border4"></div>
    </div>
    <rightBottomChart/>
  </div>
</template>

<script>
import rightBottomChart from '@/components/echart/page4/rightBottomChart'
export default {
  components:{
    rightBottomChart
  },
  data() {
    return {
      data: [
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-bottom-left-icon1.png',
          text: '果园数量',
          num: 5,
          unit: '个'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-bottom-left-icon2.png',
          text: '认养人数',
          num: 12,
          unit: '个'
        },
        // {
        //   url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-bottom-left-icon3.png',
        //   text: '认养项目',
        //   // num: ,
        //   // unit: ''
        // },
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  // margin-bottom: 35px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .item {
    display: flex;
    margin-right: 28px;

    &:nth-last-child(1) {
      margin-right: 0px;
    }
    .item-text {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.25px;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 4px;
    }

    .item-num {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1.88px;
      color: rgba(255, 255, 255, 1);
      margin-right: 5px;
    }

    .item-unit {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.25px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.5;
    }
  }

  .icon-bigBox {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(96, 112, 128, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;

    .icon-smallBox {
      width: 30px;
      height: 30px;
      border: 2px solid rgba(122, 163, 204, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .bottom-title {
    width: 100%;
    height: 29px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-top: 10px;

    &::after {
      background: linear-gradient(270deg, rgba(31, 67, 102, 0) 0%, rgba(31, 67, 102, 1) 100%);
      opacity: 0.25;
      z-index: -99;
    }

    .text {
      // margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
      text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.56px;
      line-height: 20.27px;
      color: rgba(172, 201, 230, 1);
      z-index: 99;
    }
  }

  .title-border {
    display: flex;
    margin-bottom: 12px;

    .border1 {
      width: 8px;
      height: 0;
      border: 2px solid rgba(82, 153, 204, 1);
      margin-right: 6px;
    }

    .border2 {
      width: 8px;
      height: 0px;
      opacity: 1;
      border: 2px solid rgba(41, 77, 102, 1);
      margin-right: 5px;
    }

    .border3 {
      width: 8px;
      height: 0px;
      opacity: 1;
      border: 2px solid rgba(41, 77, 102, 1);
      margin-right: 8px;
    }

    .border4 {
      width: 357.31px;
      height: 0px;
      opacity: 1;
      border: 1px solid rgba(29, 49, 64, 1);
    }
  }
}
</style>
